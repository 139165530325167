import React from 'react';
import styled from 'styled-components';
import MainTemplate from 'templates/MainTemplate/MainTemplate';
import { SectionTitle, Paragraph } from 'components/Typo';

const Wrapper = styled.div`
  width: 100%;
  padding: 120px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;

  ${({ theme }) => theme.mq.desktop} {
    width: 40%;
    margin: 0 auto;
    padding: 150px 0;
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  top: unset !important;
  align-self: unset !important;
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0;

  ${({ theme }) => theme.mq.desktop} {
    font-size: ${({ theme }) => theme.font.size.m};
  }
`;

const StyledParagraph = styled(Paragraph)`
  font-size: 1.9rem;
  line-height: 1.7;

  span {
    margin-top: 10px;
    display: block;
    font-weight: bold;
  }
`;

const Newsletter = () => {
  return (
    <MainTemplate isFooterVisible={false}>
      <Wrapper>
        <div>
          <StyledSectionTitle>Polityka prywatności</StyledSectionTitle>
          <StyledParagraph>
            Niniejsza polityka prywatności i plików cookies opisuje zasady postępowania z danymi osobowymi oraz wykorzystywania plików cookies i innych technologii w ramach strony internetowej &nbsp;
            <a href="https://helloroman.com">https://helloroman.com</a>
            &nbsp; Administratorem strony jest Adam Romański, prowadzący działalność gospodarczą pod firmą Adam Romański z siedzibą w Poznaniu 60-179,ul. Jeleniogórska 1/3A/16, wpisany do Centralnej
            Ewidencji i Informacji o Działalności Gospodarczej, posługujący się numerem NIP 6671747746 Kontakt z administratorem możliwy pod adresem e-mail helloroman.vlog@gmail.com
          </StyledParagraph>

          <StyledParagraph>
            <strong>§1 Definicje</strong>
            <br />
            1.Na potrzeby niniejszej polityki prywatności, przyjmuje się następujące znaczenie poniższych pojęć:
            <br />
            a) Administrator – Adam Romański, prowadzący działalność gospodarczą pod firmą Adam Romański z siedzibą w Poznaniu 60-179,ul. Jeleniogórska 1/3A/16, wpisany do Centralnej Ewidencji i
            Informacji o Działalności Gospodarczej, posługujący się numerem NIP 6671747746
            <br />
            b) Strona – strona internetowa dostępna pod adresem &nbsp; <a href="https://helloroman.com">https://helloroman.com</a>
            <br />
            c) Użytkownik – każdy podmiot, który korzysta ze Strony.
          </StyledParagraph>

          <StyledParagraph>
            <strong>§2 Dane osobowe</strong>
            <br />
            1. Użytkownik może przekazywać swoje dane osobowe Administratorowi za pomocą formularzy dostępnych na Stronie, takich jak newsletter.
            <br />
            2. Administratorem danych osobowych Użytkownika jest Administrator.
            <br />
            3. Dane przekazane Administratorowi w ramach formularza newsletter przetwarzane są w celu informacji o nowościach, promocjach i produktach pochodzących od Hello Roman
            <br />
            4. Administrator gwarantuje poufność wszelkich przekazanych mu danych osobowych.
            <br />
            5. Podanie danych jest zawsze dobrowolne, ale niezbędne do podjęcia przez Użytkownika akcji, do której przeznaczony jest dany formularz.
            <br />
            6. Administrator nie udostępnia przekazanych mu danych jakimkolwiek podmiotom trzecim.
            <br />
            7. Dane osobowe są gromadzone z należytą starannością i odpowiednio chronione przed dostępem do nich przez osoby do tego nieupoważnione.
            <br />
            8. Użytkownikowi przysługują następujące uprawnienia: a) prawo do żądania dostępu do danych osobowych dotyczących osoby, której dane dotyczą, ich sprostowania, usunięcia lub ograniczenia
            przetwarzania, b) prawo do wniesienia sprzeciwu wobec przetwarzania, c) prawo do przenoszenia danych, d) prawo do cofnięcia zgody na przetwarzanie danych osobowych w określonym celu,
            jeżeli Użytkownik uprzednio taką zgodę wyraził, e) prawo do wniesienia skargi do organu nadzorczego w związku z przetwarzaniem danych osobowych przez Administratora
            <br />
            9. Administrator informuje niniejszym Użytkownika, że powierza przetwarzanie danych osobowych następującym podmiotom: a) The Rocket Science Group, LLC 675 Ponce de Leon Ave NE Suite 5000
            Atlanta, GA 30308 USA – w celu korzystania z systemu mailingowego Mailchimp, służącego do przesyłania newslettera.
          </StyledParagraph>

          <StyledParagraph>
            <strong>§3 Pliki cookies</strong>
            <br />
            1. Administrator wykorzystuje pliki cookies (ciasteczka), czyli niewielkie informacje tekstowe, przechowywane na urządzeniu końcowym Użytkownika (np. komputerze, tablecie, smartfonie).
            Cookies mogą być odczytywane przez system teleinformatyczny Administratora.
            <br />
            2. Administrator przechowuje pliki cookies na urządzeniu końcowym Użytkownika, a następnie uzyskuje dostęp do informacji w nich zawartych w celach statystycznych, w celach marketingowych
            (remarketing, odzyskiwanie porzuconych koszyków) oraz w celu zapewnienia prawidłowego działania Strony.
            <br />
            3. Administrator informuje niniejszym Użytkownika, że istnieje możliwość takiej konfiguracji przeglądarki internetowej, która uniemożliwia przechowywanie plików cookies na urządzeniu
            końcowym Użytkownika. W takiej sytuacji, korzystanie ze Strony przez Użytkownika może być utrudnione.
            <br />
            4. Administrator wskazuje niniejszym, że pliki cookies mogą być przez Użytkownika usunięte po ich zapisaniu przez Administratora, poprzez odpowiednie funkcje przeglądarki internetowej,
            programy służące w tym celu lub skorzystanie z odpowiednich narzędzi dostępnych w ramach systemu operacyjnego, z którego korzysta Użytkownik.
          </StyledParagraph>

          <StyledParagraph>
            <strong>§4 Logi serwera</strong>
            <br />
            1. Korzystanie ze Strony wiąże się z przesyłaniem zapytań do serwera, na którym przechowywana jest Strona.
            <br />
            2. Każde zapytanie skierowane do serwera zapisywane jest w logach serwera. Logi obejmują m.in. adres IP Użytkownika, datę i czas serwera, informacje o przeglądarce internetowej i systemie
            operacyjnym, z jakiego korzysta Użytkownik.
            <br />
            3. Logi zapisywane i przechowywane są na serwerze.
            <br />
            4. Dane zapisane w logach serwera nie są kojarzone z konkretnymi osobami korzystającymi ze Strony i nie są wykorzystywane przez Administratora w celu identyfikacji Użytkownika.
            <br />
            5. Logi serwera stanowią wyłącznie materiał pomocniczy służący do administrowania Stroną, a ich zawartość nie jest ujawniana nikomu poza osobami upoważnionymi do administrowania serwerem.
          </StyledParagraph>
        </div>
      </Wrapper>
    </MainTemplate>
  );
};

Newsletter.propTypes = {};

export default Newsletter;
